import * as React from 'react';
import { Container, Grid } from '@mui/material';

import Env from 'common/src/env';
import { WILDCARD_STATE } from 'common/src/models/event/eventTemplate';
import useAppSelector from '../../hooks/useAppSelector';
import { useEventTemplates, useEventTicketInventories } from '../../hooks/useResource';
import { selectEventTemplateLoadStatus } from '../../redux/slices/event';
import { selectStateFilter } from '../../redux/slices/setting';

import { Loading } from 'common/src/components/base';
import EventListingCard from '../../components/EventListingCard';

interface IProps {}
const CurrentEventsTab: React.FC<IProps> = () => {
  const currentEventTemplates = useEventTemplates(/* includePastEvents */ false);
  const eventTicketInventories = useEventTicketInventories();

  const stateFilter = useAppSelector(selectStateFilter);
  const eventTemplateLoadStatus = useAppSelector(selectEventTemplateLoadStatus);

  const now = Date.now();
  const effectiveEventTemplates = Object.values(currentEventTemplates)
    .filter((eventTemplate) =>
      !eventTemplate.archived && (Env.shouldShowWIPFeatures() || eventTemplate.published),
    )
    .filter((eventTemplate_) => eventTemplate_.visible)
    .filter((eventTemplate) => {
      if (eventTemplate.location.state.trim().toLowerCase() === stateFilter) {
        return true;
      }
      if (eventTemplate.additionalAdvertisedStates.includes(WILDCARD_STATE)) {
        return true;
      }
      if (eventTemplate.additionalAdvertisedStates.filter((state) => state.trim().toLowerCase() === stateFilter).length > 0) {
        return true;
      }
      return false;
    })
    .filter((eventTemplate) => now <= eventTemplate.payload.hostedEventPayload.registrationToTs)
    .sort((e1, e2) => {
      // Sort from most to least recent (ts from lowest to highest)
      const e1Registerable = now > e1.payload.hostedEventPayload.registrationFromTs;
      const e2Registerable = now > e2.payload.hostedEventPayload.registrationFromTs;
      if (e1Registerable && e2Registerable || !e1Registerable && !e2Registerable) {
        return e1.eventFromTs - e2.eventFromTs;
      } else {
        return e1Registerable ? -1 : 1;
      }
    });

  return (
    <Grid container columnSpacing={20} rowSpacing={20}>
      {
        eventTemplateLoadStatus !== 'partial' ?
          effectiveEventTemplates.map((eventTemplate) => (
            <Grid item key={eventTemplate.id} sm={12} md={6} lg={4} display='flex'>
              <EventListingCard eventTemplateId={eventTemplate.id} eventTicketInventory={eventTicketInventories[eventTemplate.id] || null} />
            </Grid>
          )) : <Container><Loading /></Container>
      }
    </Grid>
  );
};

export default CurrentEventsTab;
