import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Tab, Tabs,
} from '@mui/material';

import { GeoStateCode, GeoStateName } from 'common/src/models/event';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { selectAllEventTemplates } from '../../redux/slices/event';
import { selectStateFilter, setStateFilter } from '../../redux/slices/setting';

import CurrentEventsTab from './CurrentEventsTab';
import PastEventsTab from './PastEventsTab';
import TaroPassTab from './TaroPassTab';

interface IProps { }
const MainContent: React.FC<IProps> = () => {
  const dispatch = useAppDispatch();

  const [tabIdx, setTabIdx] = React.useState(0);
  const { t } = useTranslation('wbevt.home');

  const stateFilter = useAppSelector(selectStateFilter);
  const loadedEventTemplates = useAppSelector(selectAllEventTemplates);

  const availableStateFilters = React.useMemo(() => {
    const statesWithEvents = Object.values(loadedEventTemplates)
      .filter((eventTemplate) => {
        if (tabIdx === 0) {
          return eventTemplate.payload.hostedEventPayload.registrationToTs >= Date.now();
        } else if (tabIdx === 1) {
          return eventTemplate.payload.hostedEventPayload.registrationToTs < Date.now();
        } else {
          return true;
        }
      })
      .map((eventTemplate) => eventTemplate.location.state.toLowerCase() as GeoStateCode);


    const uniqueStatesWithEvents = new Set(statesWithEvents);
    uniqueStatesWithEvents.add(stateFilter);

    return Object.values(GeoStateCode).filter((state) => uniqueStatesWithEvents.has(state));
  }, [loadedEventTemplates, stateFilter, tabIdx]);

  const handleChangeTabIdx = (event: React.SyntheticEvent, newTab: number) => {
    setTabIdx(newTab);
  };

  return (
    <Box>
      <Stack direction='row' mb={10} justifyContent='space-between' alignItems='center'>
        <Tabs value={tabIdx} onChange={handleChangeTabIdx}>
          <Tab label={t('landing.currentEvent')} />
          <Tab label={t('landing.pastEvent')} />
          <Tab label={t('landing.taroPass')} />
        </Tabs>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <FormControl margin='normal' size='small' sx={{ width: 160 }}>
            <InputLabel sx={{ fontSize: 14, left: 6 }}>{t('landing.stateSelectorLabel')}</InputLabel>
            <Select
              required
              label={t('landing.stateSelectorLabel')}
              value={stateFilter}
              onChange={(event: SelectChangeEvent) => {
                if (!event.target.value) {
                  return;
                }
                dispatch(setStateFilter(event.target.value as GeoStateCode));
              }}
            >
              {availableStateFilters.map((state) => (
                <MenuItem key={state} value={state}>{GeoStateName[state]}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <TabContent shown={tabIdx === 0}>
        {tabIdx === 0 && <CurrentEventsTab />}
      </TabContent>

      <TabContent shown={tabIdx === 1}>
        {tabIdx === 1 && <PastEventsTab />}
      </TabContent>

      <TabContent shown={tabIdx === 2}>
        {tabIdx === 2 && <TaroPassTab />}
      </TabContent>
    </Box>
  );
};

interface ITabContentProps {
  shown: boolean;
  children?: React.ReactNode;
}
const TabContent: React.FC<ITabContentProps> = ({ shown, children }) => {
  return (
    <Box>
      {shown && children}
    </Box>
  );
};

export default MainContent;
