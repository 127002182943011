import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { DefaultTheme } from 'common/src/constants';
import AuthProvider, { AuthContext } from 'common/src/context/auth';
import NotifierProvider from 'common/src/context/notifier';
import useAppDispatch from './hooks/useAppDispatch';
import useAppSelector from './hooks/useAppSelector';
import { RootNavigator } from './navigation';
import { setUtm } from './redux/slices/event';
import { init } from './redux/slices/init';
import store from './redux/store';

import Notifier from 'common/src/components/notifier';

import './global.css';
import './utils/localization'; // Initiates localization

// BootstrappedApp takes care of all bootstrapping (e.g. auth, wss connection, etc) needed by the app.
function BootstrappedApp() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const initStatus = useAppSelector((state) => state.init.status);
  const { authStatus } = React.useContext(AuthContext);

  // Extract utm
  React.useEffect(() => {
    const utm = searchParams.get('utm');

    if (utm) {
      dispatch(setUtm(utm));

      setSearchParams((prevSearchParams) => {
        prevSearchParams.delete('utm');
        return prevSearchParams;
      });
    }
  }, [dispatch, searchParams, setSearchParams]);

  // Manage init state
  React.useEffect(() => {
    if (authStatus === 'loggedIn' && initStatus === 'uninitialized') {
      dispatch(init()); // This will transition initStatus
    }
  }, [authStatus, initStatus, dispatch]);

  // Root is required for certain native-base components like Toast.
  return (
    <Notifier>
      <RootNavigator />
    </Notifier>
  );
}

export default function App() {
  // Redirect traffic from hellotaro.com and www.hellotaro.com to event.hellotaro.com
  React.useEffect(() => {
    if (['https://hellotaro.com', 'https://www.hellotaro.com'].includes(window.location.origin)) {
      const fullPath = window.location.href.split(window.location.origin)[1];
      const newUrl = `https://event.hellotaro.com${fullPath}`;
      window.location.href = newUrl;
    }
  }, []);

  return (
    <ThemeProvider theme={DefaultTheme}>
      <AuthProvider>
        <ReduxProvider store={store}>
          <NotifierProvider>
            <HelmetProvider>
              <BootstrappedApp />
            </HelmetProvider>
          </NotifierProvider>
        </ReduxProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
